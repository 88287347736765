.App {
  flex: 1; /* Ensures it stretches to fill the remaining space */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures spacing for footer and other sections */
  background-color: #fff8f5; /* Fix color syntax */
}

.section1 {
  background-image: linear-gradient(
    to right,
    rgba(187, 19, 20, 0.2),
    rgba(237, 85, 59, 0.2)
  ); /* Use RGBA for cleaner transparency */
  border-radius: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center; /* Ensure content inside section is centered */
}

.section1 h1 {
  color: #fff; /* Use shorthand */
}

.section1 .white-color {
  font-size: 1.25rem;
  color: #6c757d;
  max-width: 800px; /* Ensure content width is constrained */
  margin: auto; /* Center text */
}

.cta-buttons .btn-primary {
  background-color: #d90429;
  border-color: #d90429;
  border-radius: 30px;
  padding: 10px 20px;
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.cta-buttons .btn-primary:hover {
  background-color: #c40025; /* Darker red on hover */
}

.cta-buttons .btn-outline-secondary {
  border-color: #d90429;
  color: #d90429;
  border-radius: 30px;
  padding: 10px 20px;
  transition: all 0.3s ease;
}

.cta-buttons .btn-outline-secondary:hover {
  background-color: #d90429;
  color: #fff; /* Invert colors on hover */
}

.active-users {
  font-weight: bold;
  font-size: 1.1rem;
  color: #fff;
  margin-top: 15px; /* Add spacing between elements */
}

.pics-active-users {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover; /* Ensure images are correctly cropped */
}

.pics-active-users:nth-child(2) {
  left: -20px; /* Adjust overlap spacing */
}

.pics-active-users:nth-child(3) {
  left: -40px;
}

.book-cover {
  max-width: 90%;
  height: auto;
  border-radius: 10px;
  margin: auto; /* Center the book cover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add a light shadow for depth */
}

.audio-section p {
  font-weight: bold;
  color: #d90429;
}

/* Media Queries */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2rem;
  }

  .book-cover {
    margin-top: 20px;
  }
}

@media (max-width: 1200px) {
  section {
    max-width: 90%;
    margin: auto;
    padding: 10px;
  }
}

@media (max-width: 992px) {
  .card-body {
    font-size: 1rem;
  }

  .card img {
    height: 150px;
  }
}

/* New media queries for smaller screens */
@media (max-width: 768px) {
  section {
    max-width: 95%;
    padding: 5px;
  }

  h2,
  p {
    text-align: center;
    font-size: 1.2rem;
  }

  .card-body {
    padding: 10px;
    font-size: 0.95rem;
  }

  .card img {
    height: 140px;
  }
}

@media (max-width: 576px) {
  section {
    max-width: 100%;
    padding: 5px;
  }

  h2,
  p {
    font-size: 1rem;
  }

  .card-body {
    padding: 8px;
    font-size: 0.85rem;
  }

  .card img {
    height: 120px;
  }

  .card {
    margin-bottom: 20px;
  }
}

/* New media query for widths less than 350px */
@media (max-width: 320px) {
  section {
    width: 320px; /* Keep section width constant */
    margin: auto; /* Center the section */
  }

  .book-cover {
    max-width: 100%; /* Ensure the book cover fits the section */
  }
}

.custom-header {
  background-color: #fff8f5 !important;
  border: none;
}

.custom-accordion .accordion-button {
  background-color: #fff8f5;
  color: #333;
}

.custom-accordion .accordion-button:focus {
  box-shadow: none;
}

.custom-accordion .accordion-button:not(.collapsed) {
  background-color: #fff8f5 !important;
  color: #333;
}

.wrapper {
  padding: 0; /* Add some padding to the wrapper */
  margin: 0; /* Ensure there's no unwanted margin */
  background-color: #fff8f5; /* Optional: set a background color for the wrapper */
}
