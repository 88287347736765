@media (max-width: 400px) {
  .navbar-brand {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    font-size: 1rem;
    gap: 5px;
    white-space: nowrap;
    margin: 0;
  }

  .navbar-brand img {
    max-height: 1.5em;
    margin: 0;
  }

  .navbar-toggler {
    position: relative;
    z-index: 10;
    margin-right: 0;
  }

  .navbar {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .navbar-brand {
    flex: 1 1 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 375px) {
  .navbar {
    padding: 0.5rem 1rem;
  }

  .navbar-brand {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    font-size: 0.9rem;
    gap: 4px;
    white-space: nowrap;
    margin: 0;
  }

  .navbar-brand img {
    max-height: 1.2em;
    margin: 0;
  }
  .navbar-toggler {
    font-size: 0.85rem;
    padding: 0.25rem 0.5rem;
  }

  .navbar-collapse {
    flex-grow: 1;
    justify-content: center;
  }

  .nav-item {
    margin-bottom: 0.5rem;
  }

  .nav-link {
    font-size: 0.85rem;
  }
}
