/* Book Cover Container */
.book-cover-container {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 300px;
  margin: 0;
}

/* Book Cover Image */
.book-cover-image {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

/* User and Favorite Overlay (First Overlay) */
.user-favorite-overlay {
  position: absolute;
  top: -10px;
  left: -25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
  width: 70px;
  height: 35px;
  z-index: 2;
  overflow: hidden;
  border: none;
  outline: none;
}

/* Second Overlay (overlay2) - Positioned at center-right */
.user-favorite-overlay2 {
  position: absolute;
  top: 50%;
  right: -50px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
  width: 70px;
  height: 60px;
  z-index: 2;
  overflow: hidden;
  border: none;
  outline: none;
}

/* Ensure overlay images maintain aspect ratio and quality */
.user-favorite-overlay img,
.user-favorite-overlay2 img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures the image scales without stretching */
}

/* Media Player Overlay */
.media-player-overlay {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  z-index: 1;
}

/* Wrapper for Media Player */
.audio-player-wrapper {
  background: linear-gradient(
    180deg,
    rgba(136, 176, 88, 0.8),
    rgba(175, 71, 30, 0.9)
  );
  padding: 10px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Audio Player Image */
.audio-player-image {
  width: 100%;
  max-width: 240px;
  height: auto;
  object-fit: cover;
}

/* Avatar Styling */
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: -10px;
  border: 2px solid white;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .book-cover-container {
    max-width: 250px;
  }

  .user-favorite-overlay {
    width: 60px;
    height: 45px;
    top: -8px;
    left: -20px;
  }

  .user-favorite-overlay2 {
    width: 50px;
    height: 50px;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
  }

  .media-player-overlay {
    bottom: 5px;
    width: 80%;
  }

  .audio-player-wrapper {
    padding: 8px;
  }

  .audio-player-image {
    max-width: 180px;
  }

  .user-avatar {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 480px) {
  .book-cover-container {
    max-width: 220px;
  }

  .user-favorite-overlay {
    width: 50px;
    height: 40px;
    top: -5px;
    left: -15px;
  }

  .user-favorite-overlay2 {
    width: 45px;
    height: 45px;
    top: 50%;
    right: -5px;
    transform: translateY(-50%);
  }

  .media-player-overlay {
    bottom: 5px;
    width: 75%;
  }

  .audio-player-wrapper {
    padding: 6px;
  }

  .audio-player-image {
    max-width: 150px;
  }

  .user-avatar {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 400px) {
  .section1 h1 {
    font-size: 1.5rem;
    line-height: 1.2;
  }

  .section1 p {
    font-size: 0.9rem;
  }

  .cta-buttons .btn {
    font-size: 0.8rem;
    padding: 8px 16px;
  }

  .user-avatar {
    width: 30px;
    height: 30px;
  }

  .ms-3 {
    margin-left: 10px !important;
  }

  .section1 {
    padding: 20px;
    padding-left: 85px;
    padding-right: 85px;
  }
}

@media (max-width: 800px) {
  .user-favorite-overlay {
    top: -10px;
    left: -25px;
    object-fit: contain;
  }
}

@media (max-width: 750px) {
  #wrapperDiv {
    padding-left: 50px !important;
    padding-right: 50px !important;
    text-align: left !important;
  }
}
