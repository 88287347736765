/* General Section Styling */
#testimonials {
  text-align: center;
  margin: 0 auto;
  padding: 50px 0;
  background: #fff8f5;
}

/* Container Styling */
.container {
  margin: 0 auto;
  max-width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Section Title */
.section-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
}

.section-description {
  font-size: 16px;
  color: #6c757d;
  margin-bottom: 30px;
}

/* Grid Layout for Reviews */
.reviews-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(250px, 1fr)
  ); /* Responsive grid */
  gap: 20px;
  margin-top: 24px;
  padding: 0;
}

/* Review Card Styling */
.review-card {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%; /* Ensure cards stay within the container's width */
  box-sizing: border-box;
  text-align: left;
}

/* Stars Styling */
.stars {
  color: #ffd700;
  font-size: 20px;
  margin-bottom: 10px;
}

/* Review Text Styling */
.review-text {
  font-size: 15px;
  margin-bottom: 15px;
}

/* User Info Styling */
.user-info {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover; /* Ensures the image maintains aspect ratio */
  margin-right: 10px;
}

.user-name {
  font-weight: bold;
  font-size: 14px;
}

/* Media Query for Smaller Screens */
@media (max-width: 400px) {
  #testimonials {
    text-align: left;
  }
  .container {
    padding: 0 10px;
  }

  .reviews-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .review-card {
    padding: 15px;
    font-size: 14px;
    text-align: left;
  }

  .section-title {
    font-size: 24px;
  }

  .section-description {
    font-size: 14px;
  }

  .user-image {
    width: 36px;
    height: 36px;
  }

  .user-name {
    font-size: 13px;
  }

  .review-text {
    font-size: 14px;
  }
}

@media (max-width: 750px) {
  .review-text {
    text-align: left !important;
  }
}
