.section-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.section-description {
  margin-bottom: 40px;
  font-size: 16px;
  color: #777;
}

.genres-grid {
  display: flex;
  gap: 20px;
  overflow-x: auto; /* Enable horizontal scrolling */
  padding-bottom: 10px;
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  margin-top: 24px;
}

.genres-grid::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Webkit browsers */
}

/* Individual genre card styles */
.genre-card {
  flex: 0 0 auto;
  width: 220px;
  height: 220px;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  position: relative;
}

.genre-card:hover {
  transform: scale(1.05);
}

/* Genre image */
.genre-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  opacity: 0.9;
}

/* Genre title overlay */
.genre-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
}
